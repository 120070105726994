import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
import registerServiceWorker from './registerServiceWorker';

import './styles/bootstrap.min.css';
import './styles/react-bootstrap-table-all.min.css';
import './styles/app.css';
import './styles/semantic.cerulean.min.css';

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
