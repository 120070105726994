import { getAccessToken } from 'axios-jwt';
import Cookies from 'js-cookie';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

const BASE_URL = window.REACT_APP_BAYES_API;

function axiosInstance() {
  let instance = axios.create({
    baseURL: BASE_URL,
    timeout: 250000,
    headers: {
      // Get the token which was set earlier by login-form.jsx
      Authorization: `JWT ${getAccessToken()}`,
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  instance.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response === undefined) {
        NotificationManager.error(
          null,
          'Something went wrong: no response from server',
          10000,
        );
        return Promise.reject(error);
      } else if (401 === error.response.status) {
        alert('Your session has expired, please login again');
        document.getElementsByClassName('user-sign-out')[0].click();
      } else if (error.code === 'ECONNABORTED') {
        NotificationManager.error(
          null,
          'Something went wrong: request timed out',
          10000,
        );
        return Promise.reject(error);
      } else {
        NotificationManager.error(null, 'Something went wrong', 10000);
        return Promise.reject(error);
      }
    },
  );
  return instance;
}

export const createSimulationCaseResponse = (caseId, data) => {
  return axiosInstance()
    .put(`/network/simulation_case_response/${caseId}`, data)
    .then(function(response) {
      NotificationManager.success(null, 'Case response updated');
      return response;
    })
    .catch(function(error) {
      if (error.response) {
        NotificationManager.error(null, error.response.data, 10000);
      } else {
        NotificationManager.error(null, 'Request failed', 10000);
      }
    });
};

export const getAllNetworkSimulations = () =>
  axiosInstance().get('/network/simulation/');

export const getNetworkSimulation = simulationId => {
  return axiosInstance().get(`/network/simulation/${simulationId}`);
};

export const getAllNetworksFromAPI = () =>
  axiosInstance().get('/network/visualisation/');

export const getNetworkVersionsFromAPI = () => {
  return axiosInstance().get('/network/');
};

export const getNetworkFromAPI = networkId => {
  return axiosInstance().get(`/network/${networkId}`);
};

export const exportNetworkFromAPI = (networkId, fileFormat) => {
  return axiosInstance().get(`/network/export/${networkId}/${fileFormat}`);
};

export const updateNodeFromAPI = values => {
  return axiosInstance()
    .put(`/network/node/`, values)
    .then(function(response) {
      NotificationManager.success(null, 'Node updated');
      return response;
    })
    .catch(function(error) {
      if (error.response) {
        NotificationManager.error(null, error.response.data, 10000);
      } else {
        NotificationManager.error(null, 'Request failed', 10000);
      }
    });
};

export const updateNetworkFromAPI = (networkId, values) => {
  return axiosInstance()
    .put(`/network/${networkId}`, values)
    .then(function(response) {
      NotificationManager.success(null, 'Network updated');
      return response;
    })
    .catch(function(error) {
      if (error.response) {
        NotificationManager.error(null, error.response.data, 10000);
      } else {
        NotificationManager.error(null, 'Request failed', 10000);
      }
    });
};

export const createNodeFromAPI = values => {
  return axiosInstance()
    .post(`/network/node/`, values)
    .then(function(response) {
      NotificationManager.success(null, 'Node created');
      return response;
    })
    .catch(function(error) {
      if (error.response) {
        NotificationManager.error(null, error.response.data, 10000);
      } else {
        NotificationManager.error(null, 'Request failed', 10000);
      }
    });
};

export const createNetworkVersionFromAPI = (
  networkName,
  networkVersion,
  baseNetworkVersion,
) => {
  return axiosInstance()
    .post(`/network/`, {
      network_name: networkName,
      network_version: networkVersion,
      base_network_version: baseNetworkVersion,
    })
    .then(function(response) {
      NotificationManager.success(null, 'New network version created');
      return response;
    })
    .catch(function(error) {
      if (error.response) {
        NotificationManager.error(null, error.response.data, 10000);
      } else {
        NotificationManager.error(null, 'Request failed', 10000);
      }
    });
};

export const uploadNetworkVersionFromAPI = formData => {
  return axiosInstance()
    .post(`/network-upload/`, formData)
    .then(function(response) {
      NotificationManager.success(null, 'Uploaded complete');
      return response;
    })
    .catch(function(error) {
      if (error.response) {
        NotificationManager.error(null, error.response.data, 10000);
      } else {
        NotificationManager.error(null, 'Request failed', 10000);
      }
    });
};

export const getNetworkVisualisationFromAPI = (
  networkID,
  format,
  maxNodesPerLevel,
) => {
  if (typeof maxNodesPerLevel !== 'undefined') {
    return axiosInstance().get(
      `/network/visualisation/${networkID}?format=${format}&max_nodes_per_level=${maxNodesPerLevel}`,
    );
  } else {
    return axiosInstance().get(
      `/network/visualisation/${networkID}?format=${format}`,
    );
  }
};

export async function getNetworkEvaluationFromAPI(networkID) {
  let response = await axiosInstance().get(`/network/evaluation/${networkID}`);
  let networkEvaluationData = response.data;
  return simplifyNetworkEvaluationData(networkEvaluationData);
}

export function simplifyNetworkEvaluationData(networkEvaluationData) {
  const evaluationDataKeysBlacklist = ['name', 'calibrated'];
  // The API returns nodes with value = {value: 'val', 'index': 0}
  // here we convert that to value = 'val' to simplify the frontend code,
  // so it may not deal with node ordering indexes but just use the order
  // of the array items instead.
  Object.keys(networkEvaluationData).map(function(key) {
    if (evaluationDataKeysBlacklist.includes(key) === false) {
      if (networkEvaluationData[key] !== null) {
          networkEvaluationData[key].map(function(node) {
              if (node.value !== null) {
                  node.value = node.value.value;
              }
          });
      }
    }
  });
  console.log('networkEvaluationData')
  console.log(networkEvaluationData)
  return networkEvaluationData;
}

export const runNetworkEvaluationFromAPI = (
  networkID,
  evidence,
  rankingNode,
  thresholdSettings,
) => {
  if (typeof rankingNode === 'undefined' || rankingNode === null) {
    rankingNode = '';
  }
  let postData = {};
  let index = 0;
  evidence.forEach(function(value, key) {
    postData[key] = {
      value: value,
      index: index,
    };
    index = index + 1;
  });
  const thresholdPost = (thresholdSettings === null) ? {} : thresholdSettings;
  return axiosInstance()
    .post(`/network/evaluation/${networkID}?ranking_node=${rankingNode}`, {
      evidence: postData,
      threshold: thresholdPost,
    })
    .catch(error => {
      if (error.code === 'ECONNABORTED') {
        return 'timeout';
      } else {
        return 'error';
      }
    });
};

export async function requestAppToken(accessToken) {
  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 10000000,
    headers: {
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return axiosInstance.post('/account/auth/token/', {
    access_token: accessToken,
  });
}
